const isProd = process.env.NODE_ENV === 'production'
export const isOnline = ['admin.shiper123.com'].includes(location.hostname)

let apiBaseURL = '/wkapi/'
let env = 'pro' // 环境：pro-生产  dev-开发  test-测试

// 根据域名配置接口
switch (location.hostname.split('.')[0]) {
  case '192':
  case '127':
  case 'localhost':
  case 'dev-admin': // 开发环境
    env = 'dev'
    break
  case 'test-admin': // 测试环境
    env = 'test'
    break
}

export default {
  isProd,
  isOnline,
  apiBaseURL,
  env
}
