import dayjs from 'dayjs'
import { fixDate } from '@/util/wjtools/util'

export const filters = {
  // 文字超出截断
  wordSlice(word, length = 30) {
    if (!word || typeof word !== 'string') return word
    return word.length > length ? word.slice(0, length - 1) + '...' : word
  },

  // 缩写文件路径
  abridgeName(fileUrl, leftCount = 8, rightCount = 6) {
    if (!fileUrl || !fileUrl.length) return ''
    let leftLength = 0;
    let rightLength = 0;
    for (let i = 0; i < fileUrl.length; i++) {
      let w = fileUrl[i]
      let isCn = escape(w).indexOf('%u') === 0
      if (i < leftCount * 2 && leftLength < leftCount) {
        isCn ? leftLength += 1 : leftLength += 2
      } else if (i > i - rightCount && rightLength < rightCount) {
        isCn ? rightLength += 1 : rightLength += 2
      }
    }
    return fileUrl.replace(new RegExp('^(.{'.concat(leftLength, '})(.+)(.{').concat(rightLength, '})$')), '$1\u2026$3')
  },

  // 分转元
  fen2yuan(value) {
    return !isNaN(value) ? (value / 100).toFixed(2) : value
  },

  // 去掉货币符号
  delyuan(value) {
    return typeof value === 'string' ? Number(value.replace('￥', '')) : 0
  },

  /**
   * 功能：将时间戳按照给定的时间/日期格式进行处理
   * @param {Number} date 时间戳
   * @param {String} fmt 时间格式 'YYYY-MM-DD HH:mm:ss'
   * @returns {String} 规范后的 时间/日期 字符串
   */
  // fmtDate: (time, fmt) => formatDate(time, fmt),
  fmtDate(time, fmt = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) return time
    time = fixDate(time) // 格式化时间为苹果系统可兼容的格式
    return dayjs(time).format(fmt)
  },

  // 时间戳格式化为时分秒（00:00:00）
  sec2time(sec, fmt = '00:00') {
    if (!sec || sec <= 0) return fmt
    // sec = Math.floor(sec)
    let h = String(Math.floor(sec / 3600)).padStart(2, '0')
    let m = String(Math.floor(sec / 60) % 60).padStart(2, '0')
    let s = String(sec % 60).padStart(2, '0')
    if (h > 0) {
      return `${h}:${m}:${s}`
    } else {
      return `${m}:${s}`
    }
  },

  // 时分秒（00:00:00）转为时间戳
  time2sec(time) {
    if (!time) return ''
    let h = time.split(':')[0]
    let m = time.split(':')[1]
    let s = time.split(':')[2]
    return Number(h * 3600) + Number(m * 60) + Number(s)
  },

  // 数字千分位分割
  addThousands(num) {
    if (!num || num + '' === '0' ) return 0
    num = String(num)
    if (num.includes('.')) {
      return num.replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => $1 + ',')
    } else {
      return num.replace(/\d{1,3}(?=(\d{3})+$)/g, '$&,')
    }
  },

  // 截取文件名称
  subFileName(url) {
    return (url && typeof url === 'string') ? url.substr(url.lastIndexOf('/') + 1) : '--'
  }
}

export default Vue => {
  Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
  })
}
