import { render, staticRenderFns } from "./v-submenu.vue?vue&type=template&id=3a61f12a&scoped=true&"
import script from "./v-submenu.vue?vue&type=script&lang=js&"
export * from "./v-submenu.vue?vue&type=script&lang=js&"
import style0 from "./v-submenu.vue?vue&type=style&index=0&id=3a61f12a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a61f12a",
  null
  
)

export default component.exports