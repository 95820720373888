import Vue from 'vue'
import Router from 'vue-router'
import wj from '@/util/wjtools'
import istat from '@/util/istat'
import cookies from 'vue-cookies'

// 主页面布局组件
import MainPage from '@/views/layout/layout'
import routes from './routes' // 主页面子路由表

Vue.use(Router)

// 创建路由实例
const router = new Router({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: MainPage, redirect: '/home', children: routes },
    { path: '/login', name: '悟空尾派-登录', component: () => import('@/views/login/login') },
    { path: '/preview',name: '文档预览',component: () => import('@/views/system/preview') },
    { path: '/nopermission', name: '没有权限', component: () => import('@/views/layout/403') },
    { path: '/*', name: '404', component: () => import('@/views/layout/404')}
  ]
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  const token = cookies.get('token')
  const noAuth = ['/login']
  const path = to.path
  if (!noAuth.includes(path) && !token) {
    router.replace('/login')
    return
  }

  // 登录成功或点击logo会默认重定向到工作台home页，账号无此页权限时，跳到第一个有权限的菜单页
  if (to.path === '/home') {
    const menuInfo = wj.store('menuInfo') || {}
    const permission = wj.store('permission') || []
    const { firstMenuPath } = menuInfo
    if (permission.length && !permission.includes(0) && firstMenuPath) {
      router.replace(firstMenuPath)
      return
    }
  }

  next()
})

// 全局后置钩子
router.afterEach((to, from) => {
  const pageTitle = to.name || '悟空尾派'
  document.title = pageTitle

  // 页面统计
  istat.report({
    action: 'PAGE-ROUTE',
    event: 'route',
    data: { from: from.fullPath, to: to.fullPath },
  })
})

/* 正则使用 '\S' 而不是 '\d' 是为了适配魔法注释。
 * 进入 B 页面逻辑在 router.onReady 方法中。
 * 为了避免特殊情况下服务器丢失资源导致无限报错刷新，仅尝试一次进入 B 页面，如果不成功就停留在 A 页面。
 */
router.onError((error) => {
  const jsRE = /Loading chunk (\S)+ failed/g
  const cssRE = /Loading CSS chunk (\S)+ failed/g
  const isChunkLoadFailed = error.message.match(jsRE || cssRE)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    sessionStorage.setItem('router-target-path', targetPath)
    window.location.reload()
  }
})

router.onReady(() => {
  const targetPath = sessionStorage.getItem('router-target-path')
  if (targetPath) {
    sessionStorage.removeItem('router-target-path')
    const tryReload = sessionStorage.getItem('router-try-reload')
    if (!tryReload) {
      router.replace(targetPath)
      sessionStorage.setItem('router-try-reload', true)
    } else {
      sessionStorage.removeItem('router-try-reload')
    }
  }
})

export default router
