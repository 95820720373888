<template>
  <el-row class="header">
    <el-col :span="24" class="user-info">
      <template v-if="!onlyCrm">
        <router-link to="/export-records" class="link" v-report="'导出记录'">
          <i class="el-icon-download" />导出记录
        </router-link>
        <el-tooltip placement="bottom-end" v-if="admin" effect="light">
          <span style="cursor: pointer;">余额: {{balance}}</span>
          <el-table slot="content" :data="channelInfoDone" max-height="360" stripe border>
            <el-table-column prop="supplier" label="供应商" min-width="150" />
            <el-table-column label="可用金额" min-width="150">
              <template slot-scope="scope">
                <span v-for="item in scope.row._accountsDone" :key="item.currency">{{`${item.balance} ${item.currency}`}}<br></span>
              </template>
            </el-table-column>
          </el-table>
        </el-tooltip>
        <span v-else>
          余额: {{balance}}
        </span>
      </template>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{profile}}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <template v-if="!onlyCrm">
            <el-dropdown-item @click.native="showToken()" v-report="'查看开发者信息'">开发者信息</el-dropdown-item>
            <el-dropdown-item @click.native="showChannel()" v-report="'查看可用渠道'">查看可用渠道</el-dropdown-item>
          </template>
          <el-dropdown-item @click.native="logout" v-report="'退出登录'">退 出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>

    <el-dialog title="可用渠道代码" :visible.sync="dialogVisibleChannel" width="600px" top="50px" :close-on-click-modal="false">
      <el-table :data="channelProduct" stripe border style="width: 100%">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="code" label="渠道代码"></el-table-column>
        <el-table-column prop="newCode" label="新渠道代码"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="small" style="margin-left: 10px;" @click="handleCopy(scope.row.newCode, '新渠道代码已复制')">复制
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="dialogVisibleChannel=false">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 开发者信息 -->
    <el-dialog title="开发者信息" :visible.sync="dialogVisibleToken" width="500px" :close-on-click-modal="false">
      <el-form size="small">
        <el-form-item label="API Url:" :label-width="formLabelWidth" style="font-size: 18px;font-weight: 700;" >
          <a href="#" class="check" @click.prevent="handleCopy(apiData.url, '接口Url已复制')">{{apiData.url}}</a>
        </el-form-item>
        <el-form-item label="User-ID:" :label-width="formLabelWidth" style="font-size: 18px;font-weight: 700;" >
          <a href="#" class="check" @click.prevent="handleCopy(apiData.userId, 'User-ID已复制')">{{apiData.userId}}</a>
        </el-form-item>
        <el-form-item label="User-Token:" :label-width="formLabelWidth" style="font-size: 18px;font-weight: 700;"  >
          <a href="#" class="check" @click.prevent="handleCopy(apiData.token, 'User-Token已复制')">{{apiData.token}}</a>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button type="primary" @click="dialogVisibleToken = false">确 定</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
  import { mapState } from 'vuex'
  import { deepClone, copyText } from '@/util/wjtools/util'
  import NP from 'number-precision'
  import cookies from 'vue-cookies'

  export default {
    props: {
      admin: Boolean,
      channelInfo: Array,
      profile: {
        type: String,
        default: ''
      },
      balance: {
        type: Number,
        default: 0
      },
    },

    data() {
      return {
        isProd: this.$config.env === 'pro',
        apiData:{
          url: 'https://api.wukongship.com',
          userId: '',
          token: ''
        },
        formLabelWidth: "120px",
        dialogVisibleToken: false,
        dialogVisibleChannel: false,
        channelProduct: []
      }
    },

    computed: {
      ...mapState('app', ['permission']),

      onlyCrm() {
        const ids = this.permission || []
        return ((ids.length === 1) && (ids[0] === 20001))
      },

      channelInfoDone() {
        let list = deepClone([], this.channelInfo)
        list.forEach(item => {
          let obj = {}
          item.accounts && item.accounts.forEach(account => {
            if (obj[account.currency]) {
              obj[account.currency] = NP.plus(obj[account.currency] || 0, account.balance || 0)
            } else {
              obj[account.currency] = account.balance
            }
          })
          item._accountsDone = []
          for(let key in obj) {
            item._accountsDone.push({
              currency: key,
              balance: obj[key]
            })
          }
        })
        return list
      },
    },

    mounted: function () {
      this.$nextTick(function () {
        document.onclick = function () { };
      });
    },

    methods: {
      showChannel(){
        let _this = this;
        _this.$axios({
          type: "get",
          url: `channel/show`,
          withCredentials: true
        }).then(res => {
          _this.channelProduct = res.data.data;
          _this.dialogVisibleChannel = true
        }).catch(error => {
          console.log(error);
        });
      },
      showToken(){
        this.$axios({
            type: "get",
            url:`user/token`,
            params:{
              userId: ''
            }
          })
          .then(res => {
            if(res.data.success){
              this.apiData.userId = res.data.data.userId
              this.apiData.token = res.data.data.token
              this.dialogVisibleToken = true
            }else{
              this.$message.error(res.data.msg)
            }
          })
          .catch(error => {
            console.log(error);
          });
      },

      uniLogout() {
        const domain = this.isProd ? 'sso.wukongship.com' : 'dev-sso.wukongship.com'
        // 跳转地址为 `https:\/\/${domain}/?u=${encodeURIComponent(location.origin)}/login&a=exit`
        return this.$axios.get(`https://${domain}/out.php`).catch(console.error)
      },

      async logout() {
        await this.$axios.get('logout').catch(console.error)
        cookies.remove('token')
        cookies.remove('SID')
        await this.uniLogout()
        this.$router.push('/login')
      },
      // 复制文本
      handleCopy(txt, msg) {
        copyText(txt, msg)
      },
    }
  }
</script>

<style lang="scss" scoped>
  //清理浮动
  .cf {
    zoom: 1;

    &:before,
    &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }
  }
  .header {
    line-height: 60px;
    position: relative;
    overflow:hidden;
  }

  .contact-info {
    [class*="el-icon-"] {
      font-size: 20px;
      margin-right: 10px;
    }

    span+span {
      margin-left: 6%;
    }
  }

  .user-info {
    text-align: right;
    font-size: 14px;
    .link {
      margin-right: 5px;
      font-size: 14px;
      color: $blue;
      &:hover i {
        transition: .1s transform;
        transform: translateY(2px);
      }
    }
  }

  //下拉菜单
  .el-dropdown {
    padding: 0 10px;

    .el-dropdown-link {
      display: inline-block;

      &:hover {
        color: #409eff;
        cursor: pointer;
      }
    }
  }

  #chooseOperation {
    background: rgba(0, 0, 0, 0.7);

    .custom-dialog {
      width: 500px;

      .custom-header h4 {
        text-align: left;
      }

      .custom-body {
        padding: 15px;

        .form-group .form-control {
          width: 100%;
        }

        .form-group .el-select {
          width: 100%;
        }

        .form-group .el-select .el-input__inner {
          height: 34px;
        }

        .button-group {
          padding: 0 15px;
          text-align: center;

          .btn {
            width: 100%;
            margin: 0;
            letter-spacing: 3px;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {}

  .check {
    display: inline-block;
    vertical-align: top;
    font-size: 14px !important;
    /* text-decoration: underline; */
    color:rgb(46, 109, 245) !important;
  }
</style>
