import wj from '@/util/wjtools'

export default {
  setMenuInfo(state, payload) {
    state.menuInfo = payload
    wj.store('menuInfo', payload)
  },
  setPermission(state, payload) {
    state.permission = payload
    wj.store('permission', payload)
  },
}
