<template>
  <div>
    <el-submenu v-if="item.children && item.children.length" :index="item.label">
      <template slot="title">
        <iconsvg v-if="item.icon" :name="item.icon" />
        <span>{{ item.label }}</span>
      </template>

      <VSubmenu v-for="(sub, i) in item.children" :key="i" :item="sub" />
    </el-submenu>

    <el-menu-item v-else :index="item.path">
      <template slot="title">
        <iconsvg v-if="item.icon" :name="item.icon" />
        <span>{{ item.label }}</span>
      </template>
    </el-menu-item>
  </div>
</template>

<script>
  import VSubmenu from './v-submenu'
  export default {
    name: 'VSubmenu',
    components: {
      VSubmenu,
    },
    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {}
    },
  }
</script>
<style lang="scss" scoped>
.iconsvg {
  margin-right: 4px;
}
</style>
